import AssetMixin from '@/mixins/asset.mixin'
import Date from '@/components/inputs/Date/Date.vue'
import SimSlots from '@/components/inputs/SimSlots/SimSlots.vue'

export default {
  props: ['asset', 'extraField'],
  mixins: [AssetMixin],
  components: {
    Date,
    SimSlots
  },
  data() {
    return {
      showPassword: false,
      loadingPassword: false,
      retrievePassword: false,
      editRMobileSupportId: true,
      editRMobileSupportPassword: false,
      duplicateRMobileSupportId: false,
      field: {
        name: null,
        value: null,
        rules: null
      }
    }
  },
  computed: {
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp,
        md6: this.extraField.name !== 'imeis'
      }
    },
    onEditMode() {
      return !_.isEmpty(this.asset.id)
    },
    hasRMobileSupportId() {
      return this.asset.extraFields['rMobileSupportId'] !== null
    },
    hasRMobileSupportPasswordVersion() {
      return this.asset.extraFields['rMobileSupportPasswordVersion'] !== null
    }
  },
  watch: {
    asset() {
      if (
        !_.isEqual(
          this.field.value,
          this.asset.extraFields[this.extraField.name]
        )
      ) {
        this.field.value = this.asset.extraFields[this.extraField.name]
      }
    },
    'field.value': {
      deep: true,
      handler(value) {
        if (this.extraField.name === 'rMobileSupportId') {
          const field = _.find(
            this.$parent.$parent.inputs,
            (x) => x.id === 'rMobileSupportPasswordVersion'
          )
          field.validate()
        }
        if (this.extraField.name === 'rMobileSupportPasswordVersion') {
          const field = _.find(
            this.$parent.$parent.inputs,
            (x) => x.id === 'rMobileSupportId'
          )
          field.validate()
        }
        this.$emit('update', {
          name: this.extraField.name,
          value
        })
      }
    },
    'asset.purchaseDate': {
      deep: true,
      handler() {
        const field = _.find(
          this.$parent.$parent.inputs,
          (x) => x.label === 'Initial Deployment Date'
        )
        if (field) {
          field.focus()
          field.validate()
          field.blur()
        }
      }
    }
  },
  apollo: {
    getRMobileSupport: {
      query() {
        const fields = this.getFieldsGql('read', 'RMobileSupport', [
          'rMobileSupportPassword'
        ])
        return this.$gql`
          query RMobileSupport(
            $rMobileSupportId: String!,
            $simId: ID
          ) {
            rMobileSupport(rMobileSupportId: $rMobileSupportId, simId: $simId) {
              rMobileSupportPassword
            }
          }
        `
      },
      variables() {
        return {
          rMobileSupportId: this.asset.extraFields['rMobileSupportId'],
          simId: this.asset.id
        }
      },
      skip() {
        return !this.onEditMode || !this.editRMobileSupportPassword
      },
      update: () => null,
      result({ data, error }) {
        if (data.rMobileSupport) {
          this.field.value = data.rMobileSupport.rMobileSupportPassword
        } else {
          this.field.value = null
        }
        this.loadingPassword = false
      }
    }
  },
  methods: {
    async validateField(value, path) {
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.asset.id) {
        this.whereConditions.push({
          path: 'id',
          comparison: 'equal',
          value: this.asset.id,
          negate: true
        })
      }

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
                  query assets($where: [WhereExpression]) {
                    ${this.currentType.listQueryName}(where: $where) {
                      totalCount
                    }
                  }
                `,
          variables: { where: this.whereConditions }
        })

        if (data && data[this.currentType.listQueryName]) {
          const assets = data[this.currentType.listQueryName]
          const x =
            _.find(
              this.currentType.fields,
              (x) => x.name === this.whereConditions[0].path
            ).displayName || this.whereConditions[0].path
          if (value !== null && value.length > 0 && assets.totalCount > 0) {
            this.duplicateRMobileSupportId = true
          } else {
            this.duplicateRMobileSupportId = false
          }
        } else if (error) {
          this.graphQLOnError(
            `Failed to validate asset data from server. ${error.toString()}`
          )
          this.assets = {}
        } else {
          this.graphQLOnError('Failed to connect to server.')
          this.assets = {}
        }
      }
    },
    async validateRMobileSupportId() {
      await this.validateField(
        this.asset.extraFields['rMobileSupportId'],
        'rMobileSupportId'
      )
    },
    unlockSupportId() {
      this.editRMobileSupportId = !this.editRMobileSupportId
      if (!this.editRMobileSupportId) {
        const field = _.find(
          this.$parent.inputs,
          (x) => x.label === 'R Mobile Support Password'
        )
        field.reset()
        this.asset.extraFields['rMobileSupportPasswordVersion'] = null
      }
    },
    unlockSupportPassword() {
      this.editRMobileSupportPassword = !this.editRMobileSupportPassword
      this.retrievePassword = true
      if (
        this.asset.extraFields['rMobileSupportPasswordVersion'] !== null &&
        !this.showPassword
      ) {
        this.loadingPassword = !this.loadingPassword
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    setFieldRules(fieldName) {
      let rules = []
      switch (fieldName) {
        case 'initialDeploymentDate':
          rules = [
            (v) =>
              (v &&
                this.$moment(v).format('YYYYMMDD') >=
                  this.$moment(this.asset.purchaseDate).format('YYYYMMDD')) ||
              !this.asset.purchaseDate ||
              !v ||
              'Must be on or beyond Purchase Date'
          ]
          break
        case 'rMobileSupportPasswordVersion':
          rules = [
            (v) =>
              !v ||
              !!this.asset.extraFields['rMobileSupportId'] ||
              'R Mobile Support ID cannot be blank',
            (v) =>
              !(!v && this.asset.extraFields['rMobileSupportId']) ||
              'R Mobile Support Password cannot be blank'
          ]
          break
        case 'rMobileSupportId':
          rules = [
            (v) =>
              !v ||
              !!this.asset.extraFields['rMobileSupportPasswordVersion'] ||
              'R Mobile Support Password cannot be blank',
            (v) =>
              !(
                !v && this.asset.extraFields['rMobileSupportPasswordVersion']
              ) || 'R Mobile Support ID cannot be blank',
            (v) => {
              if (v && !/^[a-zA-Z\d]*$/.test(v)) {
                return 'R Mobile Support ID should only contains a-z, A-Z, 0-9'
              }
              return true
            },
            !this.duplicateRMobileSupportId ||
              'Sim with same R Mobile Support ID exists'
          ]
          break
      }
      return rules
    },
    getFieldDisplayName(f) {
      return f.displayName || _.startCase(f.name)
    }
  },
  created() {
    this.field.value = this.asset.extraFields[this.extraField.name]
  }
}
