import AssetMixin from '@/mixins/asset.mixin'

export default {
  props: {
    asset: { type: Object },
    objects: { type: Array, default: () => [] },
    disabled: Boolean
  },
  mixins: [AssetMixin],
  data() {
    return {
      simSlots: [],
      availableSims: [],
      assignedSims: [],
      iMEIRules: [
        v => (_.isEmpty(v) && _.isEmpty(_.get(_.find(this.simSlots, x => x.iMEI === v), 'sim'))
                || !_.isEmpty(v) && _.isEmpty(_.get(_.find(this.simSlots, x => x.iMEI === v), 'sim'))
                || !_.isEmpty(v) && !_.isEmpty(_.get(_.find(this.simSlots, x => x.iMEI === v), 'sim')))
                || 'IMEI cannot be blank',
        v => _.every(v, s => /^[0-9]*$/.test(s)) || 'Must be a valid IMEI',
        v => _.isEmpty(v) || v.length === 15 || v.length === 17 || 'Must be 15 or 17 digits',
        v => _.isEmpty(v) || _.filter(this.simSlots, { iMEI: v }).length <= 1 || 'Must be unique'
      ],
      simRules: [
        v => _.isEmpty(v) || _.filter(this.simSlots, { sim: v }).length <= 1 || 'Must be unique',
        v => (!_.isEmpty(v) && !_.isEmpty(_.get(_.find(this.simSlots, x => x.sim === v), 'iMEI'))
                || _.isEmpty(v) && !_.isEmpty(_.get(_.find(this.simSlots, x => x.sim === v), 'iMEI'))
                || _.isEmpty(v) && _.isEmpty(_.get(_.find(this.simSlots, x => x.sim === v), 'iMEI')))
                || 'IMEI cannot be blank'
      ]
    }
  },
  watch: {
    objects: {
      handler() {
        this.updateSimSlots()
      },
      deep: true
    },
    simSlots: {
      handler() {
        if (this.$refs && this.$refs.imeis) {
          this.$refs.imeis.forEach(f => f.validate())
        }
        if (this.$refs && this.$refs.sims) {
          this.$refs.sims.forEach(f => f.validate())
        }
        this.emit()
      },
      deep: true
    }
  },
  methods: {
    updateSimSlots() {
      this.simSlots = []
      if (!_.isEmpty(this.objects)) {
        this.objects.forEach(x => {
          if (_.get(x, 'sim.name')) {
            this.assignedSims.push({ id: x.sim.id, name: x.sim.name })
            this.assignedSims = _.uniqBy(this.assignedSims, 'id')
          }
          this.simSlots.push(this.createSimSlot(x.id, x.iMEI, x.sim))
        })
      } else {
        this.addSimSlot()
      }
    },
    createSimSlot(id = '', iMEI = '', sim = '') {
      return { id, iMEI, sim }
    },
    addSimSlot() {
      this.simSlots.push(this.createSimSlot())
    },
    removeSimSlot(i) {
      this.simSlots.splice(i, 1)
      if (this.simSlots.length === 0) {
        this.addSimSlot()
      }
    },
    emit() {
      if (!_.isEqual(this.simSlots, this.objects)) {
        this.$emit('update:objects', this.simSlots)
      }
    }
  },
  apollo: {
    availableSims: {
      query() {
        const fields = this.getFieldsGql('read', 'Sim', ['id', 'name'])
        return this.$gql`
          query sims($where: [WhereExpression]) {
            sims(where: $where) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      variables() {
        const where = [
          { path: 'simSlot', value: [null] },
          { path: 'assignee', value: [null] }
        ]
        if (this.asset.region !== null) {
          where.push({ path: 'region', comparison: 'equal', value: [this.asset.region.text.toUpperCase()] })
        }
        return { where }
      },
      skip() {
        return !this.ability.can('read', 'Sim')
      },
      update({ sims }) {
        return sims.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get sims. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    this.updateSimSlots()
  }
}
